import React, { useEffect, useState } from 'react';
import { getDocs, collection,query,orderBy,doc, updateDoc } from 'firebase/firestore';
import { db } from "../firebase";

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const handleValideClick = async (index) => {
        const item = data[index];
        const itemRef = doc(db, 'courses', item.id);
        await updateDoc(itemRef, {
            valide: true
        });
        // Update the local state to reflect the change
        const updatedData = [...data];
        updatedData[index].valide = true;
        setData(updatedData);
    };
    const handlePhoneClick = (index) => {
        console.log("phone : ",data[index].phone);
        const phoneNumber = data[index].phone;
        window.location.href = `tel:${phoneNumber}`;
      };

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const coursescollection = collection(db, 'courses');

            const q1 = query(
                coursescollection,
                orderBy('createdDate', 'desc'),
                // orderBy('title', 'desc')
              );

            const snapshot = await getDocs(q1);
            let filteredDocs = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
                        setData(filteredDocs);
            setLoading(false);
            console.log("data ", filteredDocs);
        }

        getData();
    }, []); // Empty dependency array to run only once on mount

    return (
    <div>
            {loading ? (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-75 z-50">
                <div className="border-8 border-dashed rounded-full animate-spin border-termine w-[10vw] h-[10vw] lg:w-[2vw] lg:h-[2vw]"></div>
            </div>
            ) : (
                <div className="flex flex-col gap-4 md:flex-row md:flex-wrap">
                    {/* Render data */}
                    {data.map((item, index) => (
                        <div key={index} className={`m-3 p-4 rounded-3xl shadow-md ${item.valide==true ? 'bg-lightGreen' : 'bg-white'}`}>
                        <h2 className="text-lg font-semibold">Name: {item.name}</h2>
                        <p>Category: {item.category}</p>
                        <p>Created Date: {new Date(item.createdDate).toLocaleDateString()}</p>
                        <p>Distance: {item.distance} km</p>
                        <p>From: {item.from}</p>
                        <p>To: {item.to}</p>
                        <p>Phone: {item.phone}</p>
                        <p>Pickup Date : {item.pickupDateTime ? new Date(item.pickupDateTime.seconds * 1000).toLocaleString() : 'N/A'}</p>
                        <p>Price: {item.price} €</p>
                        <div className='Row flex justify-between'>
    <div>
        {item.valide === false && (
            <button onClick={() => handleValideClick(index)} className="mt-2 bg-green hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                Validé
            </button>
        )}
    </div>
    <div>
        <button onClick={() => handlePhoneClick(index)} className="mt-2 bg-green hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
            call
        </button>
    </div>
</div>

                    </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Home;
