// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCOfHlJnFSQ8Ls2Bvn4XVIzKqtA1smgyu0",
    authDomain: "my-travel-comfort.firebaseapp.com",
    projectId: "my-travel-comfort",
    storageBucket: "my-travel-comfort.appspot.com",
    messagingSenderId: "1097190302364",
    appId: "1:1097190302364:web:dc34c1983422e3f9c28359",
    measurementId: "G-1XX4844Y87"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };