import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import LogIn from './pages/LogIn';
import Middelware from './pages/middelware'; // Corrected import name

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<div><Middelware></Middelware> <Home /></div>}  />
                <Route path="/sign-in" element={<LogIn />} />
                {/* Add a route for handling invalid routes */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
