import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Middelware = () => {
    const navigate = useNavigate();


    useEffect(() => {
        const checkAuth = () => {
            const connected = localStorage.getItem("connected");
            console.log("connected ",connected)
            if (!connected) {
                console.log("innn");
                navigate('/sign-in', { replace: true });

            }
        }
        checkAuth();
    }, []);

    return (
        <></>
    )
}
export default Middelware;